<template>
  <div>
    <div class="filters">
      <records-tab-filters
        :selected-status="filters.selectedStatus"
        :from="filters.from"
        :till="filters.till"
        :plate="filters.plate"
        @filter="onFilter"
      >
      </records-tab-filters>
    </div>

    <card-component
      class="has-table has-mobile-sort-spaced"
      icon="video"
      :title="'Anpr data (' + Intl.NumberFormat().format(recordsCount) + ')'"
      :has-button-slot="true"
    >
      <div slot="button" class="buttons" v-if="filters.selectedStatus !== 0">
        <b-button type="is-grey" size="is-small" icon-left="download" @click="exportModal">
          Export
        </b-button>
      </div>
      <records-tab-table
        :data="records"
        :loading="newRecordsLoading"
        :sorting="sorting"
        @sort="onSort"
        @validateAnprRecord="validateRecord"
        @whitelistAnprRecord="whitelistRecord"
        @deleteAnprRecord="deleteRecord"
        @saveLicensePlate="saveNewLicensePlate"
      >
      </records-tab-table>
    </card-component>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import anpr from './../store/anpr.store';
import CardComponent from './../../CardComponent.vue';
import RecordsTabTable from './../components/RecordsTabTable.vue';
import RecordsTabFilters from './../components/RecordsTabFilters.vue';
import ExportModal from './../components/RecordsTabExportModal.vue';

export default {
  name: 'AnprPage',
  components: {
    CardComponent,
    RecordsTabTable,
    RecordsTabFilters,
    ExportModal,
  },

  props: {
    active: Boolean,
  },

  data: () => ({ cameraId: null }),

  // TODO: find solution for this dirty check, interceptor, parent page, ...
  created() {
    if (!anpr.isRegistered) {
      this.$store.registerModule('anprv2', anpr);
      anpr.isRegistered = true;
    }
  },

  beforeMount() {
    this.cameraId = parseInt(this.$route.params.id);
    this.getCameraRecords(this.filters, this.sorting);
  },

  mounted() {
    window.onscroll = () => {
      // If this is not the active tab, then ignore scroll to bot
      if (!this.active) return;

      //  < 1 is used as error margin 
      // -> when someone is zoomed in or out the infinite scroll stops working otherwise
      const bottomOfWindow =
        document.documentElement.offsetHeight  - (document.documentElement.scrollTop + window.innerHeight) < 1;

      if (!bottomOfWindow) return;
      this.fetchNextAnprCameraRecords({ cameraId: this.cameraId });
    };
  },

  methods: {
    ...mapActions('anprv2', [
      'fetchNewAnprCameraRecords',
      'fetchNextAnprCameraRecords',
      'validateAnprCameraRecord',
      'whitelistAnprCameraRecord',
      'deleteAnprCameraRecord',
      'saveLicensePlate',
    ]),
    ...mapMutations('anprv2', ['setAllRecordsExported']),

    validateRecord(props) {
      this.validateAnprCameraRecord(props).catch((rec) =>
        this.handleRecordError(rec, 'validating')
      );
    },
    whitelistRecord(props) {
      this.whitelistAnprCameraRecord(props).catch((rec) =>
        this.handleRecordError(rec, 'whitelisting')
      );
    },
    deleteRecord(props) {
      this.deleteAnprCameraRecord(props).catch((rec) => this.handleRecordError(rec, 'deleting'));
    },

    saveNewLicensePlate(props) {
      this.saveLicensePlate(props).catch(({ oldPlate, newPlate }) =>
        this.$buefy.toast.open({
          message:
            'Oops, something went wrong. Reverted updated plate ' +
            newPlate +
            ' back to ' +
            oldPlate,
          type: 'is-danger',
        })
      );
    },

    handleRecordError(record, action) {
      this.$buefy.toast.open({
        message: 'Oops, something went wrong with ' + action + ' ' + record.license_plate,
        type: 'is-danger',
      });
    },

    onFilter(filters) {
      this.getCameraRecords(filters, this.sorting);
    },
    onSort(sorting) {
      this.getCameraRecords(this.filters, sorting);
    },

    getCameraRecords(filters, sorting) {
      this.fetchNewAnprCameraRecords({ cameraId: this.cameraId, filters, sorting }).catch((_) =>
        this.$buefy.toast.open({
          message: 'Oops, failed to load new Anpr records',
          type: 'is-danger',
        })
      );
    },

    getNextCameraRecords() {
      this.fetchNextAnprCameraRecords({ cameraId: this.cameraId }).catch((_) =>
        this.$buefy.toast.open({
          message: 'Oops, failed to load the next Anpr records',
          type: 'is-danger',
        })
      );
    },

    exportModal() {
      this.$buefy.modal.open({
        parent: this,
        component: ExportModal,
        props: {
          statusId: this.filters.selectedStatus,
        },
        hasModalCard: true,
        trapFocus: true,
        events: {
          export: this.export,
        },
      });
    },

    export({ format, process }) {
      const params = this.createExportParams(format, process, this.filters);
      this.$axios
        .get('/api/cameras/' + this.cameraId + '/anpr/export', {
          params,
          responseType: 'blob',
        })
        .then((response) => {
          if (format.type !== 'cevi') {
            const filename = 'anprrecords_' + params.toString() + format.extension;
            this.forceFileDownload(response, filename);
          }
          if (process) this.setAllRecordsExported();
        })
        .catch((err) => {
          this.$buefy.toast.open({
            message: 'Oops, something went wrong with the export',
            type: 'is-danger',
          });
          throw err;
        });
    },

    createExportParams(format, process, filters) {
      const params = new URLSearchParams();
      params.append('type', format.type);
      params.append('status', filters.selectedStatus);
      if (process) params.append('process', process);
      if (filters.from) params.append('from', this.formatDate(filters.from));
      if (filters.till) params.append('till', this.formatDate(filters.till));
      if (filters.plate) params.append('plate', filters.plate);
      return params;
    },

    formatDate: (date) => date.toString().substring(0, date.toString().indexOf('(') - 1),

    forceFileDownload(response, title) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', title);
      document.body.appendChild(link);
      link.click();
    },
  },

  computed: {
    ...mapGetters('anprv2', {
      newRecordsLoading: 'getAnprCameraRecordsLoading',
      filters: 'getCameraRecordFilters',
      sorting: 'getCameraRecordSorting',
      records: 'getAnprCameraRecords',
      recordsCount: 'getAnprCameraRecordsCount',
    }),
  },
};
</script>

<style scoped lang="scss">
.filters {
  padding-top: unset;
  padding-bottom: unset;
}
</style>
