<template>
  <b-field class="tag-scope" >
    <b-tag
      size="is-medium"
      closable
      aria-close-label="Close tag"
      @close="$emit('removePlate', plate)"
    >
      <div>
        {{ plate.plate }}
        <b-tooltip v-if="plate.from || plate.till" type="is-dark" multilined>
          <b-icon class="icon is-info" icon="clock" size="is-small" />
          <template v-slot:content>
            <p v-if="plate.from">From: {{ formatDateToLocal(plate.from) }}</p>
            <p v-if="plate.till">Till: {{ formatDateToLocal(plate.till) }}</p>
          </template>
        </b-tooltip>
      </div>
    </b-tag>
  </b-field>
</template>

<script>
export default {
  props: {
    plate: Object,
  },

  methods: {
    formatDateToLocal(date) {
      return this.$moment.utc(date).format('DD/MM/YYYY HH:mm:ss z');
    },
  },
};
</script>

<style lang="scss">
.field.tag-scope {
  .tag:not(body).is-medium {
    > span {
      width: 100%;
      text-align: center;
    }
  }
}
</style>

<style lang="scss" scoped>
.tag:not(body).is-medium {
  width: 250px;
  justify-content: space-between;
}

.tag:not(body) .icon:first-child:last-child {
  margin-left: 5px;
}

.field.tag-scope {
  display: inline-block;
  margin-right: 1rem;
  margin-bottom: 1rem;
}
</style>