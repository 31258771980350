<template>
  <div>
    <section class="section is-main-section">
      <card-component
        class="has-table has-mobile-sort-spaced"
        title="Available anpr camera's"
        icon="map-marker"
        :has-button-slot="true"
      >
      </card-component>

      <div class="columns is-multiline">
        <div
          class="column  is-half-desktop "
          style="cursor: pointer;"
          v-for="camera in cameras"
          :key="camera.id"
          @click="navigateDetail(camera.id)"
        >

          <card-component>
            <div class="media">
              <div class="media-left">
                <eye-icon :status="camera.project.status" type="project" :has-text="false"/>
              </div>
              <div class="media-content">
                <p class="title is-5">{{ camera.package.name }} - {{ camera.name }}</p>
                <p class="subtitle is-6">{{ camera.project.address }} {{ camera.project.nr }} {{
                    camera.project.town
                  }}
                </p>

                <hr>

                <b-field grouped group-multiline>
                  <div class="control">
                    <b-taglist attached>
                      <b-tag size="is-small" type="is-warning is-light">To be validated</b-tag>
                      <b-tag size="is-small" type="is-warning">{{ camera.anpr_tovalidate }}</b-tag>
                    </b-taglist>
                  </div>
                  <div class="control">
                    <b-taglist attached>
                      <b-tag type="is-success is-light">Validated</b-tag>
                      <b-tag type="is-success">{{ camera.anpr_validated }}</b-tag>
                    </b-taglist>
                  </div>
                  <div class="control">
                    <b-taglist attached>
                      <b-tag type="is-info is-light">Validated & Exported</b-tag>
                      <b-tag type="is-info">{{ camera.anpr_exported }}</b-tag>
                    </b-taglist>
                  </div>
                </b-field>

              </div>

              <div class="media-right">
                <b-tag icon="office-building" v-if="camera.project.company">{{ camera.project.company.name }}</b-tag>
              </div>
            </div>
          </card-component>


        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

import anpr from './../store/anpr.store';
import CardComponent from './../../CardComponent.vue';
import ProjectItemComponent from "@/components/project/ProjectItemComponent";
import EyeIcon from "@/components/EyeIcon";

export default {
  name: 'AnprsPage',
  components: {EyeIcon, ProjectItemComponent, CardComponent},

  // TODO: find solution for this dirty check, interceptor, parent page, ...
  created() {
    if (!anpr.isRegistered) {
      this.$store.registerModule('anprv2', anpr);
      anpr.isRegistered = true;
    }
  },

  beforeMount() {
    this.fetchAnprCameras();
  },

  methods: {
    ...mapActions('anprv2', ['fetchAnprCameras']),

    navigateDetail(id) {
      this.$router.push({name: 'anpr', params: {id}});
    },
  },

  computed: {
    ...mapGetters('anprv2', {
      cameras: 'getAnprCameras',
      loading: 'getAnprCamerasLoading',
    }),
  },
};
</script>

<style scoped lang="scss">
.anpr-tile {
  display: inline-block;
  margin: 0.5rem;
  padding-left: 0.4em;
  padding-right: 0.4em;

  min-width: 150px;
  height: 75px;
  line-height: 75px;

  border-radius: 0.5rem;
  background-color: rgba(150, 150, 150, 0.1);

  vertical-align: middle;
  text-align: center;

  &:hover {
    background-color: rgba(150, 150, 150, 0.3);
    cursor: pointer;
  }
}
</style>
